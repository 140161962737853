import { onError } from "./errorLib"

export async function reverseGeocode({ lat, lon }) {
  return fetch(
    `https://us1.locationiq.com/v1/reverse.php` +
    `?key=a46735d3264459` +
    `&lat=${lat}` +
    `&lon=${lon}` +
    `&namedetails=1` +
    `&extratags=1` +
    `&normalizecity=1` +
    `&statecode=1` +
    `&format=json`
  ).then((response) => {
    if (!response.ok) {
      console.error(response.statusText);
    }
    return response.json()
  }).catch((error) => {
    console.error(error);
  })
}

export async function getLocationByIp() {
  return fetch(
    `https://ipapi.co/json/`
  ).then((response) => {
    if (!response.ok) {
      console.error(response.statusText);
    }
    return response.json()
  }).catch((error) => {
    console.error(error);
  })
}


function getBoundingBox(LngLat, pDistanceInMeters) {
  function toRad(val) {
    return val * Math.PI / 180.0;
  }

  const { lat, lng } = LngLat;

  const latRadian = toRad(lat);

  const degLatKm = 110.574235;
  const degLongKm = 110.572833 * Math.cos(latRadian);
  const deltaLat = pDistanceInMeters / 1000.0 / degLatKm;
  const deltaLong = pDistanceInMeters / 1000.0 / degLongKm;


  const topLat = lat + deltaLat;
  const bottomLat = lat - deltaLat;
  const leftLng = lng - deltaLong;
  const rightLng = lng + deltaLong;


  const maxLon = rightLng
  const maxLat = topLat
  const minLon = leftLng
  const minLat = bottomLat
  const boundingBox = [maxLon, maxLat, minLon, minLat];

  return boundingBox;
}

export function locationNameMaker(v) {
  const secondaryName = v.address?.neighbourhood ? v.address.neighbourhood : v.address?.suburb ? v.address.suburb : null

  let title
  let extras
  if (v.namedetails?.name) {
    // with name
    title = v.namedetails.name
    extras =
      (v.address?.house_number ? v.address.house_number + " " : "") +
      (v.address?.road ? v.address.road + ", " : "") +
      (secondaryName ? secondaryName + ", " : "") +
      (v.address?.city ? v.address.city + ", " : "") +
      (v.address?.state_code ? v.address.state_code.toUpperCase() + " " : "") +
      (v.address?.postcode ? v.address.postcode + " " : "")
  } else {
    // no name
    title = secondaryName ? secondaryName : v.address?.road ? v.address.road : ""
    extras =
      (v.address?.house_number ? v.address.house_number + " " : "") +
      (v.address?.road ? v.address.road + ", " : "") +
      (v.address?.city ? v.address.city + ", " : "") +
      (v.address?.state_code ? v.address.state_code.toUpperCase() + " " : "") +
      (v.address?.postcode ? v.address.postcode + " " : "")
  }

  console.log(v)
  return { title, extras, secondaryName, display_name: v.display_name }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export async function lookupGeocode(query, center, radius) {

  if (!query) {
    return;
  }

  const bbox = getBoundingBox(center, radius)
  const language = "en"
  const [maxLon, maxLat, minLon, minLat] = bbox
  const { lat, lng } = center
  const limit = 5

  for (let i = 0; i < 5; i++) {
    try {
      const response = await fetch(
        // `https://api.locationiq.com/v1/autocomplete.php
        `https://us1.locationiq.com/v1/search.php` +
        `?key=a46735d3264459` +
        `&q=${query}` +
        `&normalizecity=1` +
        `&namedetails=1` +
        `&addressdetails=1` +
        `&statecode=1` +
        `&extratags=1` +
        `&matchquality=1` +
        `&accept-language=${language}` +
        `&viewbox=${maxLon},${maxLat},${minLon},${minLat}` +
        `&limit=${limit}` +
        `&bounded=1` +
        `&proximity=${lng},${lat}` +
        `&countrycodes=us` +
        `&format=json`);
      if (response.ok) {
        return await response.json()
      } else if (response.status === 404) {
        console.log("No results")
        return null
      } else if (response.status === 429 || response.status === 500) {
        console.log("Thottled geocoding")
        await sleep(randomNumber(500, 5000));
      } else {
        console.log("Some other geocoding problem")
        await sleep(randomNumber(500, 5000));
      }
    } catch (error) {
      onError(error);
    }
  }
  onError("Maximum retry error.");
}
