// react 
import { useState } from "react";

// libs
import dateFormat from "dateformat"
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { saveActivity } from "../libs/activitiesLib";
import { useAppContext } from "../libs/contextLib";


// UI
import { Table, Form, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSquare } from "@fortawesome/free-solid-svg-icons";


TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

export default function ActivityList({ activities, onClick }: { activities: any[], onClick: (activity: any) => void }) {
  const { user, selectedTimeZone } = useAppContext();

  enum Sort { Date = "Date", Title = "Title", Location = "Location", Time = "Time", ActivityId = "ActivityId", Published = "Published" }
  const [sort, setSort] = useState<Sort>(Sort.Title)
  const [sortFilter, setSortFilter] = useState<string>("")
  const [selectedActivityId, setSelectedActivityId] = useState<string | undefined>(undefined);

  let filtered = activities

  switch (sort) {
    case Sort.Date:
      filtered = activities.filter((a) => {
        if (a.anytime) return sortFilter.length > 0 ? false : "anytime".includes(sortFilter.toLowerCase())
        const startDate = a.startTime && dateFormat(a.startTime.toDate(), "dddd, mmmm dS")
        return startDate.toLowerCase().includes(sortFilter.toLowerCase())
      })
      break;
    case Sort.Time:
      filtered = activities.filter((a) => {
        if (a.anytime) return sortFilter.length > 0 ? false : "anytime".includes(sortFilter.toLowerCase())
        const timeago = a.startTime && timeAgo.format(a.startTime.toDate())
        return timeago.toLowerCase().includes(sortFilter.toLowerCase())
      })
      break;
    case Sort.Title:
      filtered = activities.filter((a) => (a.title.toLowerCase().includes(sortFilter.toLowerCase())))
      break;
    case Sort.Location:
      filtered = activities.filter((a) => (a.locationName.toLowerCase().includes(sortFilter.toLowerCase()) || a.locationAddress.toLowerCase().includes(sortFilter.toLowerCase())))
      break;
    case Sort.ActivityId:
      filtered = activities.filter((a) => (a.activityId.toLowerCase().includes(sortFilter.toLowerCase())))
      break;
    case Sort.Published:
      filtered = activities.filter((a) => (a.title.toLowerCase().includes(sortFilter.toLowerCase())))
      break;
  }

  filtered.sort((a, b) => {
    if (a.anytime) {
      return 1
    }
    if (b.anytime) {
      return -1
    }

    return (a.startTime >= b.startTime ? 1 : -1)
  })

  const activityTableList = filtered.map((activity, index) => {

    // const startDateString = !activity.anytime ? dateFormat(activity.startTime.toDate(), "dddd, mmmm dS") : "anytime"

    const startDateString = !activity.anytime ? activity.startTime.toDate().toLocaleString('en-US', { dateStyle: 'full', timeStyle: 'long', timeZone: selectedTimeZone }) : "anytime"

    var timeago = !activity.anytime ? activity.startTime.toDate().toLocaleString('en-US', { timeZone: selectedTimeZone }).split(' ') : ["", "anytime", ""]
    timeago = timeago[1] + " " + timeago[2]


    const expired = !activity.anytime ? Date.now() - activity.startTime.toDate() > 0 : false

    return (
      <tr
        style={{ backgroundColor: activity.activityId === selectedActivityId ? expired ? "pink" : "lightgrey" : expired ? "red" : "" }}
        key={index} onClick={(e: any) => {
          setSelectedActivityId(activity.activityId)
          onClick(activity)
        }}>
        <td style={{ fontFamily: "Courier New" }}>{activity.activityId.substring(0, activity.activityId.length / 2)}<br></br>{activity.activityId.substring(activity.activityId.length / 2)}</td>
        <td>{startDateString}</td>
        <td>{timeago}</td>
        <td>{activity.title}</td>
        <td>{activity.locationName} {activity.locationAddress}</td>
        <td><Button variant="secondary" onClick={() => {
          activity.publish = !activity.publish

          //recreate the startDate and startTime values so when we edit the activity they stay the same
          let startTime = null
          let startDate = null
          if (activity.startTime) {
            const startDateTime = activity.startTime.toDate()
            // break starttime up into date and time
            startTime = `${startDateTime.getHours()} ${startDateTime.getMinutes()}`
            //set time in date portion to 0 since this matches the options
            startDateTime.setHours(0, 0, 0)
            startDate = `${startDateTime}`
          }

          const dl = { anytime: activity.anytime, startTime: startTime, startDate: startDate, activityId: activity.activityId }
          user && saveActivity(activity, dl, user)
        }}><FontAwesomeIcon icon={activity.publish ? faCheck : faSquare} /></Button></td>
      </tr >
    )
  })

  return (
    <div>
      <Form.Control type="text" placeholder={`Filter ${sort}`} value={sortFilter} onChange={(e) => setSortFilter(e.target.value)} />
      <div>
        <Table>
          <thead>
            <tr>
              <th style={{ background: sort == Sort.ActivityId ? "tan" : "" }}
                onClick={() => {
                  setSort(Sort.ActivityId)
                  setSortFilter("")
                }}>ActivityId</th>
              <th
                style={{ background: sort == Sort.Date ? "tan" : "" }}
                onClick={() => {
                  setSort(Sort.Date)
                  setSortFilter("")
                }}>Date</th>
              <th
                style={{ background: sort == Sort.Time ? "tan" : "" }}
                onClick={() => {
                  setSort(Sort.Time)
                  setSortFilter("")
                }}>Time</th>
              <th
                style={{ background: sort == Sort.Title ? "tan" : "" }}
                onClick={() => {
                  setSort(Sort.Title)
                  setSortFilter("")
                }}>Title</th>
              <th
                style={{ background: sort == Sort.Location ? "tan" : "" }}
                onClick={() => {
                  setSort(Sort.Location)
                  setSortFilter("")
                }}>Location</th>
              <th style={{ background: sort == Sort.Published ? "tan" : "" }}
                onClick={() => {
                  setSort(Sort.Published)
                  setSortFilter("")
                }}>Published</th>
            </tr>
          </thead>
          <tbody >
            {activityTableList}
          </tbody>
        </Table>
      </div>
    </div>
  )
}