import { useEffect, useRef, useState } from "react";

import maplibregl from "maplibre-gl";
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';

import { reverseGeocode, lookupGeocode } from '../libs/mapLib'


import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import "maplibre-gl/dist/maplibre-gl.css";
import "./MapLibre.css"

export default function MapLibre({ className = "map", onMove, ipCenter }) {
  const mapContainer = useRef(null);

  const [center, setCenter] = useState(ipCenter)
  const [map, setMap] = useState(null)

  function isEqual(a, b) {
    if (a.lat === b.lat && a.lon === b.lon) return true
    return false
  }

  useEffect(() => {
    if (map) setCenter(prev => (isEqual(prev, ipCenter) ? prev : ipCenter))
  }, [ipCenter])

  useEffect(() => {
    if (map) map.setCenter(center)
  }, [center])


  useEffect(() => {
    const initializeMap = async () => {
      if (!(mapContainer && mapContainer.current)) {
        return
      }

      //process.env.MAPTILER_API_KEY
      const map = new maplibregl.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/streets/style.json?key=9DZctZMf1TsirGU2rqcp`,
        center: ipCenter,
        zoom: 14
      });

      setMap(map)

      const marker = new maplibregl.Marker({
        draggable: true,
      })

      marker.setLngLat(ipCenter)
      marker.addTo(map)

      map.on('move', (e) => {
        marker.setLngLat(e.target.transform.center)
      });

      map.on('moveend', (e) => {
        onMove(e.target.transform.center)
      });

      map.on("load", () => {
        console.info("Loaded Map")
      });

      map.scrollZoom.disable();

      const geocoderApi = {
        forwardGeocode: async (config) => {
          console.log(config)

          const center = { lat: config.proximity[1], lng: config.proximity[0] }
          const query = config.query
          const limit = config.limit

          const data = await lookupGeocode(query, center, 50000)

          console.log(data)
          // const data = await Geo.searchByText(config.query, {
          //   biasPosition: config.proximity,
          // });
          const features = data.map((result) => {
            const { lat, lon, ...otherResults } = result;

            const point = { lat, lon }
            return {
              type: "Feature",
              geometry: { type: "Point", coordinates: point },
              properties: { ...otherResults },
              place_name: otherResults.display_name,
              text: otherResults.display_name,
              center: point,
            };
          });
          return { features };
        },
        reverseGeocode: async (config) => {
          console.log(config)
          // const data = await Geo.searchByCoordinates(config.query);
          // const { geometry, ...otherResults } = data;
          // const feature = {
          //   type: "Feature",
          //   geometry: { type: "Point", coordinates: geometry.point },
          //   properties: { ...otherResults },
          //   place_name: otherResults.label,
          //   text: otherResults.label,
          //   center: geometry.point,
          // };
          // return { features: [feature] };
        },
      };

      const geocoder = new MaplibreGeocoder(geocoderApi, { maplibregl: maplibregl });
      map.addControl(geocoder);
      geocoder.on("error", (error) => {
        console.log(error);
      });


    };

    initializeMap()
    // eslint-disable-next-line
  }, [mapContainer]);

  return (
    < div
      className={className}
      ref={el => (mapContainer.current = el)
      } />
  );
};
