import dateFormat from 'dateformat';

function getDaysArray(start: Date, end: Date) {
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export function getDaysList(timeZone: string) {
  const date = new Date();
  const forwardDays = 27;

  const time = 1000 * 60 * 5;
  const minDate = new Date(date.getTime() + time - (date.getTime() % time));
  const maxDate = new Date(new Date((Date.now()) + 1000 * 60 * 60 * 24 * forwardDays))
  return getDaysArray(minDate, maxDate).map((v, i) => {
    v.setHours(0, 0, 0)

    const days = v.toLocaleString('en-US', { dateStyle: 'full', timeStyle: undefined, timeZone: timeZone })

    return { text: days, value: { date: v, index: i } }
  });
}

export function makeDays(timeZone: string, supplumental?: Date[]) {
  const sup = (supplumental || []).filter((v) => { return v !== null }).map((v, i) => {
    console.log({ v })
    v.setHours(0, 0, 0)
    const days = v.toLocaleString('en-US', { dateStyle: 'full', timeStyle: undefined, timeZone: timeZone })
    return { text: days, value: { date: v, index: i } }
  });

  const dayslist = getDaysList(timeZone);

  return [...dayslist, ...sup].map((d, index) => {
    return <option key={`daylist_${index}`} value={`${d.value.date}`}>{d.text}</option>;
  });
}

export function getTimesList() {
  const quarterHours = ["00", "15", "30", "45"];
  const amHours = ["5", "6", "7", "8", "9", "10", "11",];
  const pmHours = ["12", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10",];
  const times: any[] = [];
  let x = 5; // since we are skipping over 12 1 2 3 4

  amHours.forEach(h => {
    quarterHours.forEach(m => {
      times.push({ value: `${x} ${m}`, display: `${h}:${m} am` });
    });
    x = x + 1;
  });

  pmHours.forEach(h => {
    quarterHours.forEach(m => {
      times.push({ value: `${x} ${m}`, display: `${h}:${m} pm` });
    });
    x = x + 1;
  });

  return times
}


export function makeTimes() {
  const times = getTimesList()
  return times.map((d, index) => {
    return <option key={`timelist_${index}`} value={d.value}>{d.display}</option>;
  });
}
