import { LinkContainer } from "react-router-bootstrap";

// UI
import { Navbar, Nav, Dropdown, NavItem, NavLink, Image, Form } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiceD20, faUserFriends, faAddressCard } from '@fortawesome/free-solid-svg-icons'

// firebase
import firebase from "firebase";

// app context
import { useAppContext } from "../libs/contextLib";

import timezones from "timezones.json"

export function LoggedOutNav() {
  return (
    <Navbar sticky="top" bg="light">
      <LinkContainer to="/">
        <Navbar.Brand>Vivacio.us</Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer to="/">
            <Nav.Link><FontAwesomeIcon icon={faDiceD20} /></Nav.Link>
          </LinkContainer>

        </Nav>
        <Nav>
          <LinkContainer to="/login">
            <Nav.Link>Login</Nav.Link>
          </LinkContainer>
        </Nav>

      </Navbar.Collapse>
    </Navbar>
  )
};

export function LoggedInNav({ selectedTimeZoneState }: { selectedTimeZoneState: [string, React.Dispatch<React.SetStateAction<string>>] }) {

  const [selectedTimeZone, setSelectedTimeZone] = selectedTimeZoneState

  const { user } = useAppContext();
  // firebase 
  const auth = firebase.auth()

  function signOut() {
    auth.signOut()
  }

  const displayName = !user?.isAnonymous && user?.displayName ? user?.displayName : "Anonymous";
  const photoUrl = user?.photoURL || '/images/profile_placeholder.png';

  const timezonesNames = timezones.reduce((pv: any, cv: any) => {
    return [...pv, ...cv.utc]
  }, [])

  const timezoneOptions = timezonesNames.map((tz, i) => {
    return <option key={`tz_${i}`} value={`${tz}`} >{tz}</option>
  })

  let usDate = new Date().toLocaleString('en-US', { timeZone: selectedTimeZone });

  return (
    <Navbar sticky="top" bg="light">
      <LinkContainer to="/">
        <Navbar.Brand>
          Stumble {" "}
          {usDate}
        </Navbar.Brand>

      </LinkContainer>
      <Form.Control as="select" value={selectedTimeZone} onChange={(e) => {
        setSelectedTimeZone(e.target.value)
      }}>
        {timezoneOptions}
      </Form.Control>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Nav className="mr-auto">
      </Nav>
      <Nav>
        <Dropdown alignRight as={NavItem} id="collasible-nav-dropdown">
          <Dropdown.Toggle as={NavLink}>
            <Image
              width={20}
              height={20}
              src={photoUrl}
              roundedCircle />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>{displayName}</Dropdown.Item>
            <Dropdown.Divider />
            <LinkContainer to="/hosted">
              <Dropdown.Item><FontAwesomeIcon icon={faUserFriends} /> Hosted Activities</Dropdown.Item>
            </LinkContainer>
            <LinkContainer to="/stumbled">
              <Dropdown.Item><FontAwesomeIcon icon={faAddressCard} /> Stumbled Activities</Dropdown.Item>
            </LinkContainer>
            <LinkContainer to="/stumbleon">
              <Dropdown.Item><FontAwesomeIcon icon={faAddressCard} /> Stumble on Activities</Dropdown.Item>
            </LinkContainer>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => signOut()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar >
  )
};