// react 
import { useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";

// libs
import { JSONstringifyOrder } from "../libs/JSONstringifyOrder";
import { saveActivity } from "../libs/activitiesLib";

// UI
import { Button, Col, Row, Container, Modal, Tab, Tabs } from "react-bootstrap"
import Sticky from 'react-stickynode';
import StickyContainer from 'react-stickynode'

// Components
import CreateActivity from "../components/CreateActivity";
import ActivityList from "../components/ActivityList";

// firebase
import firebase from "firebase";

// CSS
import "./HostedActivities.css";


export default function HostedActivities() {
  const { user } = useAppContext();

  const [myHostedActivities, setMyHostedActivities] = useState<any[]>([]);
  const [selectedActivityId, setSelectedActivityId] = useState<string | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<String | null>("")
  const [selectedActivity, setSelectedActivity] = useState<any>(null);

  // on page load
  // Create a query for all of a users activities
  // Start listening to the query.
  // return unsubscribe for subscription cleanup when function isn't needed anymore.
  useEffect(() => {
    if (!user) {
      return
    }

    var unsubscribe = firebase.firestore()
      .collection('activities').where("hostId", "==", user.uid)
      .onSnapshot(function (snapshot) {
        const hostedActivities = snapshot.docs.map((d) => {
          return { ...d.data(), activityId: d.id }
        })
        setMyHostedActivities(hostedActivities)
      });

    return function cleanup() {
      console.log("Unsubscribing from my hosted activities")
      setMyHostedActivities([])
      unsubscribe()
      setSelectedActivity(null)
    }
  }, [user])

  useEffect(() => {
    myHostedActivities.map((d) => {
      if (d.activityId === selectedActivityId) {
        setSelectedActivity(d)
      }

    })
  }, [selectedActivityId, myHostedActivities])

  function onActivitySelection(activity: any) {
    setSelectedActivityId(activity.activityId)
  }

  function handleNewActivityClick() {
    if (!user) {
      return
    }
    const activity = {}
    const dl = { anytime: true }
    saveActivity(activity, dl, user)
  }

  return (
    <div className="Hosted">
      <div className="lander">
        <StickyContainer>
          <Container fluid>
            <Sticky enabled={true} >
              <Button variant="primary" onClick={handleNewActivityClick}>
                New Activity
              </Button>
            </Sticky>
            <Row lg="2" className="holder">
              <Col className="lister">
                <ActivityList
                  activities={myHostedActivities}
                  onClick={(activity) => onActivitySelection(activity)} />
              </Col>
              <Col className="viewer">
                {selectedActivity ?
                  <Tabs defaultActiveKey="json" onSelect={(key) => setSelectedTab(key)} transition={false}>
                    <Tab className="content" eventKey="json" title="Json">
                      <div ><pre style={{ fontFamily: "Courier New", overflowWrap: 'break-word', textAlign: "left", wordBreak: "break-all" }}>
                        {JSONstringifyOrder(selectedActivity, 2)}
                      </pre></div>
                    </Tab>
                    <Tab className="content" eventKey="edit" title="Edit"  >
                      {selectedTab === "edit" ? <div>
                        <CreateActivity selectedActivity={selectedActivity} />
                      </div> : <></>}
                    </Tab>

                  </Tabs>
                  : <></>}
              </Col>
            </Row>
          </Container>
        </StickyContainer>
      </div>
    </div >
  );
}
