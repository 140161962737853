import * as geofire from "geofire-common";
import { v4 as uuidv4 } from 'uuid';
import firebase from "firebase";


// Add a new activity entry to the database.
// Upload activity images.
export async function saveActivity(activity: any, activityStartTime: any | null, user: firebase.User) {

  //remove depricated keys
  if (!!activity.acceptedCount) {
    activity.acceptedCount = firebase.firestore.FieldValue.delete()
  }

  const seriesId = activity.seriesId || uuidv4();

  // if (activity?.title.length <= 0 || activity?.description.length <= 0) {
  //   alert("You must have a title and description.");
  //   return;
  // }

  // if (!activity?.locationLat || !activity?.locationLon || !activity?.locationName || !activity?.locationAddress) {
  //   alert("You must select a location on the map.");
  //   return;
  // }

  // if ((!activityStartTime.startTime || !activityStartTime.startDate) && !activityStartTime.anytime) {
  //   console.log(activityStartTime);
  //   alert("You need to define a date and time.");
  //   return;
  // }

  // if (activity.mediaUrls.length <= 0) {
  //   console.error('Activity must have at least one image');
  //   return;
  // }

  if (!activityStartTime.anytime) {
    const t = activityStartTime.startTime.split(" ");
    const h = Number(t[0]);
    const m = Number(t[1]);

    const startTime = new Date(activityStartTime.startDate);
    startTime.setHours(h, m, 0);

    activity.startTime = firebase.firestore.Timestamp.fromDate(startTime);
    activity.anytime = false;
  } else {
    activity.startTime = null //firebase.firestore.FieldValue.delete()
    activity.anytime = true;
  }

  //activity information
  activity.publish = activity.publish || false
  activity.seriesId = seriesId
  activity.hostId = user.uid
  activity.hostName = user.displayName || "Anonymous"
  activity.hostPhotoUrl = user.photoURL || '/images/profile_placeholder.png'
  activity.title = activity.title || ""
  activity.description = activity.description || ""
  activity.maxAttendees = activity.maxAttendees || 0
  activity.locationAddress = activity.locationAddress || ""
  activity.locationName = activity.locationName || ""
  // activity.locationLat = activity.locationLat
  // activity.locationLon = activity.locationLon
  activity.keywords = activity.description?.match(/#[a-z]+/gi) || []
  activity.attributes = ["public"]
  activity.mediaUrls = activity.mediaUrls || []
  // activity.hash = geofire.geohashForLocation([activity.locationLat, activity.locationLon])

  if (activityStartTime.activityId) {
    activity.startTime = activity.startTime || firebase.firestore.FieldValue.delete()
    return firebase.firestore().collection('activities').doc(activityStartTime.activityId).update(activity).then(() => console.log('Edited activity', { activity })).catch((error: any) => {
      console.error('Error writing new activity to database', error)
    })
  }

  console.log("Writing activity to firestore", { activity })

  return firebase.firestore().collection('activities').add(activity).then(() => console.log('Wrote activity', { activity })
  ).catch((error: any) => console.error('Error writing new activity to database', error))
}


export async function deleteActivity(activityId: string) {
  console.log("Deleting activity", { activityId });
  firebase.firestore()
    .collection('activities')
    .doc(activityId)
    .delete()
    .then(() => {
      console.log("Host removed activity", { activityId });
    })
    .catch((error) => console.error("Error occured while writing activities to stumble", { error }));
}
