import { useState, useEffect } from 'react';

// libraries
// import { onError } from "./libs/errorLib"
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";

// UI
import { LoggedInNav, LoggedOutNav } from './components/TopBar';
import { Alert } from 'react-bootstrap';

// firebase
import firebase from "firebase";

// CSS
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


function App() {
  // This Effect configures firebase servcies
  // subscribe to auth state and update internal state to match
  // and set if notification token is found
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isTokenFound, setTokenFound] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)
  const selectedTimeZoneState = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [selectedTimeZone, setSelectedTimeZone] = selectedTimeZoneState

  useEffect(() => {
    // register callback on auth state changed
    firebase.auth().onAuthStateChanged((user) => {



      if (user) {
        setUser(user)
        saveMessagingDeviceToken(user)
        setIsAuthenticated(true);
        setIsAuthenticating(false)

        console.log("Authenticated", { user })
      }
      else {
        setIsAuthenticated(false);
        setUser(null)
      }
    })

    firebase.messaging.isSupported() && firebase.messaging().onMessage((payload: any) => {
      console.log("Received notification payload", payload);
    })

    console.log("App loaded")
    // eslint-disable-next-line
  }, []); // ensure function runs only once


  // Saves the messaging device token to the datastore.
  function saveMessagingDeviceToken(user: firebase.User) {
    firebase.messaging.isSupported() && firebase.messaging().getToken().then(function (currentToken) {
      if (currentToken) {
        console.log('Got FCM device token:', currentToken);
        // Saving the Device Token to the datastore.
        setTokenFound(true);
        firebase.firestore().collection('fcmTokens').doc(currentToken)
          .set({ uid: user.uid });
      } else {
        // Need to request permissions to show notifications.
        requestNotificationsPermissions(user);

        setTokenFound(false);
      }
    }).catch(function (error) {
      console.error('Unable to get messaging token.', error);
    });
  }

  // Requests permission to show notifications.
  function requestNotificationsPermissions(user: firebase.User) {
    console.log('Requesting notifications permission...');
    Notification.requestPermission().then(function () {
      // Notification permission granted.
      saveMessagingDeviceToken(user);
    }).catch(function (error) {
      console.error('Unable to get permission to notify.', error);
    });
  }

  return (
    <div className="App container-fluid">
      <AppContext.Provider value={{ isAuthenticated, user, selectedTimeZone }}>
        {!isTokenFound && <Alert variant="danger"> Notifications disabled</Alert>}
        {isAuthenticated ? <LoggedInNav selectedTimeZoneState={selectedTimeZoneState} /> : <LoggedOutNav />}
        <header className="App-header">
          {isAuthenticating ? <FontAwesomeIcon spin icon={faSpinner}
            style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} size="9x" /> : <Routes />}
        </header>
      </AppContext.Provider>
    </div>
  );
}

export default App;
