// react
import { Link } from "react-router-dom";

// CSS
import "./Landing.css";

export default function Landing() {
  return (
    <div className="Landing">
      <div className="lander">
        <div className="Landing">
          <div className="lander">
            <h1>Vivacio.us</h1>
            <p>Be vivacious.</p>
          </div>
        </div>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          {" "}
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    </div>
  );
}
