
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

// Style the Button component


const FileUploader = props => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleInput = event => {
    props.onInput(event);
  };
  return (
    <div>
      <div style={{ position: "relative" }}>
        <Button style={{ position: "absolute", margin: "1em" }}
          onClick={handleClick}>
          <FontAwesomeIcon icon={faUpload} />
        </Button>
      </div>
      <img
        src={"https://via.placeholder.com/160x377"}

        style={{ width: "100%", maxHeight: "100em", maxWidth: "10em", borderRadius: "1em", margin: "0.4em" }} />

      <input
        multiple
        type="file"
        accept="image/*"
        ref={hiddenFileInput}
        onInput={handleInput}
        style={{ display: 'none' }}
      />
    </div>
  );
}
export default FileUploader;