// react 
import { useAppContext } from "../libs/contextLib";

// UI
import { Jumbotron, Container, } from "react-bootstrap";


export default function Home() {
  const { user } = useAppContext();


  return (
    <div className="Home">
      <div className="lander">
        <Container fluid>
          <Jumbotron>
            <h1>Get Ready to Stumble</h1>
            <p>Welcome {user?.displayName}</p>
          </Jumbotron>
        </Container>
      </div>
    </div >
  );
}
