// libraries
import { Route, Redirect, Switch } from "react-router-dom";
import { useAppContext } from "./libs/contextLib";

// pages
import Login from "./containers/Login";
import Home from "./containers/Home";
import Landing from "./containers/Landing";
import HostedActivities from "./containers/HostedActivities";
import StumbledActivities from "./containers/StumbledActivities";
import StumbleOnActivities from "./containers/StumbleOnActivities";

export default function Routes() {
  const { isAuthenticated } = useAppContext();
  return (
    <Switch>
      <Route exact={true} path="/"
        render={() => { return isAuthenticated ? <Home /> : <Landing /> }} />
      <Route exact={true} path="/login"
        render={() => { return !isAuthenticated ? <Login /> : <Redirect to="/" /> }} />
      <Route exact={true} path="/hosted"
        render={() => { return isAuthenticated ? <HostedActivities /> : <Landing /> }} />
      <Route exact={true} path="/stumbled"
        render={() => { return isAuthenticated ? <StumbledActivities /> : <Landing /> }} />
      <Route exact={true} path="/stumbleon"
        render={() => { return isAuthenticated ? <StumbleOnActivities /> : <Landing /> }} />
      <Route render={() => { return <Redirect to="/" /> }} />
    </Switch >
  )
}