import { getBase64URL, resizeAndCrop } from "./crop";
import { v4 as uuidv4 } from 'uuid';
import firebase from "firebase";

export function uploadMedia(mediaUpload: any[], keywords: string[] = []) {
  if (mediaUpload.length > 5)
    return [];

  const fileSnapshotsPromise = Promise.all(mediaUpload.map(async (file) => {
    const blob = await getBase64URL(file).then((base64URL) => {
      return resizeAndCrop(base64URL);
    });

    var filePath = 'images/' + `${uuidv4()}.jpeg`;
    return firebase.storage().ref(filePath).put(blob, { contentType: "image/jpeg" });
  }));

  fileSnapshotsPromise.then((fileSnapShots) => Promise.all(fileSnapShots.map(async (fileSnapshot) => {
    const mediaUrl = await fileSnapshot.ref.getDownloadURL()
    const mediaUri = fileSnapshot.metadata.fullPath
    return firebase.firestore().collection('media').add({ mediaUri, mediaUrl, keywords }).then(() => console.log('Wrote image info', { mediaUri, mediaUrl, keywords })
    ).catch((error: any) => console.error('Error writing image info to database', error))
  }))).then((imageInfo) => {
    console.log('uploaded images', imageInfo);
  })
  return fileSnapshotsPromise.then((fileSnapshots) => Promise.all(fileSnapshots.map((fileSnapshot) => fileSnapshot.ref.getDownloadURL())));

}
