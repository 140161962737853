// react 
import { useEffect, useState } from "react"
import { useAppContext } from "../libs/contextLib"

// libs
import { reverseGeocode, locationNameMaker } from "../libs/mapLib"
import { getBase64URL, resizeAndCrop } from "../libs/crop"
import { getDaysList, getTimesList, makeDays, makeTimes } from "../libs/dateLib"
import { deleteActivity, saveActivity } from "../libs/activitiesLib"
import { uploadMedia } from "../libs/uploadMedia"

// firebase
import firebase from "firebase";

// UI
import { Button, Form, Col, Modal, InputGroup } from "react-bootstrap"
import { faCheck, faClock, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Components
import MapLibre from "./MapLibre"
import ImageCollage from "./ImageCollage"
import FileUploader from "./FileUploader"

// CSS

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')


export default function CreateActivity({ selectedActivity }: { selectedActivity: any }) {
  const { user, selectedTimeZone } = useAppContext();

  const [showGalleryModal, setShowGalleryModal] = useState<boolean>(false)
  const [seriesActivites, setSeriesActivities] = useState<any[]>([])
  const [dateList, setDateList] = useState<any[]>([{ activityId: null, anytime: null, startDate: null, startTime: null }]);
  const [uploading, setUploading] = useState(false);
  const [mediaUpload, setMediaUpload] = useState<any[]>([]);
  const [activity, setActivity] = useState<any>({});

  useEffect(() => {
    if (!user) {
      return
    }

    console.log("Subscribing to", { selectedActivity, seriesId: selectedActivity.seriesId })

    var unsubscribe = firebase.firestore()
      .collection('activities').where("seriesId", "==", selectedActivity.seriesId)
      .onSnapshot(function (snapshot) {
        const sa = snapshot.docs.map((d) => {
          return { ...d.data(), activityId: d.id }
        })
        setSeriesActivities(sa)
      });

    return function cleanup() {
      console.log("Unsubscribing from my series activities")
      setSeriesActivities([])
      setMediaUpload([])
      unsubscribe()
    }
  }, [user, selectedActivity])

  useEffect(() => {
    //get datelist from all activities in series
    //set activity to activityId
    const dl = seriesActivites.map((a) => {

      if (a.activityId === selectedActivity.activityId) {
        setActivity({ ...a })
      }

      if (a.anytime) {
        return { activityId: a.activityId, anytime: true, startTime: null, startDate: null }
      }

      const startDateTime = a.startTime.toDate()
      // break starttime up into date and time
      const startTime = `${startDateTime.getHours()} ${zeroPad(startDateTime.getMinutes(), 2)}`
      //set time in date portion to 0 since this matches the options
      startDateTime.setHours(0, 0, 0)
      const startDate = `${startDateTime}`

      console.log("Dates", { startTime, startDate })
      return { activityId: a.activityId, anytime: false, startTime, startDate }
    })

    setDateList(dl)

  }, [seriesActivites])

  function addDate() {
    if (!user) {
      return
    }

    const dl = { anytime: true, startTime: null, startDate: null, activityId: null }
    saveActivity(activity, dl, user)
  }

  function removeDate(activity: any) {
    deleteActivity(activity.activityId)
  }

  function removeImage(url: string) {
    //add new media files to mediaUrls
    const mediaUrls = activity.mediaUrls.filter((u: string) => u !== url)
    activity.mediaUrls = mediaUrls
    setActivity((prevState: any) => ({ ...prevState, mediaUrls: mediaUrls }))

    saveSeries()
  }

  function addImage(url: string) {
    //add new media files to mediaUrls
    const mediaUrls = [...activity.mediaUrls, url]
    activity.mediaUrls = mediaUrls
    setActivity((prevState: any) => ({ ...prevState, mediaUrls: mediaUrls }))

    saveSeries()
  }

  function saveSeries() {
    if (!user) {
      return
    }

    return Promise.all(dateList.map((dl: any) => {
      return saveActivity(activity, dl, user)
    }))
  }

  async function saveMedia(mediaUpload: any[]) {
    setMediaUpload(mediaUpload)
    //upload any images that need uploading
    const urls = await uploadMedia(mediaUpload)

    //add new media files to mediaUrls
    const mediaUrls = [...urls, ...activity.mediaUrls]
    activity.mediaUrls = mediaUrls
    setActivity((prevState: any) => ({ ...prevState, mediaUrls: mediaUrls }))

    saveSeries()?.then(() => {
      setMediaUpload([])
    })
  }

  async function handleSaveActivity() {
    setUploading(true)

    saveSeries()?.then(() => {
      setUploading(false)
    })
  };

  function handleShowGalleryModal() {
    setShowGalleryModal(true)
  }

  function handleCloseGalleryModal() {
    setShowGalleryModal(false)
  }

  function GalleryModal() {
    const [galleryMedia, setGalleryMedia] = useState<any[]>([])

    useEffect(() => {
      console.log("Subscribing to gallery")

      var unsubscribe = firebase.firestore()
        .collection('media')
        .onSnapshot(function (snapshot) {
          const sa = snapshot.docs.map((d) => {
            return { ...d.data(), mediaId: d.id, add: true }
          })
          setGalleryMedia(sa)
        });

      return function cleanup() {
        console.log("Unsubscribing from gallery")
        setGalleryMedia([])
        unsubscribe()
      }
    }, [])


    return (
      <Modal
        size="xl"
        fullscreen="true"
        show={showGalleryModal}
        onHide={handleCloseGalleryModal}
        backdrop="static"
        keyboard={false}>

        <Modal.Header closeButton>
          <Modal.Title>Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageCollage mediaUrls={galleryMedia} onAdd={(url: string) => addImage(url)} > </ImageCollage>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseGalleryModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    )
  }

  function renderPicker(index: number, activity: any) {
    return (
      <Form.Row key={`picker_row_${index}`}>
        <Col xs={1}>
          <Button className="my-1 mr-sm-2"
            variant={activity.anytime ? "primary" : "disabled"}
            onClick={() => {
              const newDateList = dateList.map((dl) => {
                if (dl.activityId === activity.activityId) {
                  if (dl.anytime) {
                    return { ...dl, anytime: false, startDate: getDaysList(selectedTimeZone)[0].value.date, startTime: getTimesList()[0].value }
                  } else {
                    return { ...dl, anytime: true }
                  }

                }
                return dl
              })
              setDateList(newDateList)
            }}>
            <FontAwesomeIcon icon={!activity.anytime ? faClock : faCheck} />
          </Button>
        </Col>
        <Col xs={7}>
          {!activity.anytime && <Form.Control
            as="select"
            placeholder="Select Date"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            value={dateList[index].startDate || ""}
            onChange={(e) => {
              const dl = [...dateList]
              dl[index].startDate = e.target.value
              setDateList(dl)
            }}
            custom
          >
            <option key="day_option_base" />
            {makeDays(selectedTimeZone, dateList[index].startDate && [new Date(dateList[index].startDate)])}
          </Form.Control>}
        </Col>
        <Col >
          {!activity.anytime && <Form.Control
            as="select"
            placeholder="Select Time"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            value={dateList[index].startTime || ""}
            onChange={(e) => {
              const dl = [...dateList]
              dl[index].startTime = e.target.value
              setDateList(dl)
            }}
            custom
          >
            <option />
            {makeTimes()}
          </Form.Control>}
        </Col>
        <Col xs="auto" className="my-1 mr-sm-2">
          <Button variant="danger" onClick={() => removeDate(activity)
          }><FontAwesomeIcon icon={faMinus} /> </Button>
        </Col>
      </Form.Row >
    )
  }

  function renderPickerList() {
    return (
      <div>
        <Form.Row>
          <Col xs={2}>
            <Form.Label>Anytime</Form.Label>
          </Col>
          <Col xs={7}>
            <Form.Label>Date</Form.Label>
          </Col>
          <Col >
            <Form.Label>Time</Form.Label>
          </Col>
          <Col xs="auto" className="my-1 mr-sm-2">
            <Form.Label>Action</Form.Label>
          </Col>
        </Form.Row>

        {dateList.map((v: any, i: number) => renderPicker(i, v))}
        <Form.Row>
          <Col xs={2}>
          </Col>
          <Col xs={7}>

          </Col>
          <Col >

          </Col>
          <Col xs="auto" className="my-1 mr-sm-2">
            <Button variant="success" onClick={() => addDate()} >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        </Form.Row >
      </div>
    )
  }

  let displayMedia = mediaUpload.map((f) => {
    return { mediaUrl: URL.createObjectURL(f), upload: true }
  })
  if (activity?.mediaUrls?.length > 0) {
    const mediaUrls = activity!.mediaUrls.map((f: string) => {
      return { mediaUrl: f, upload: false }
    })

    displayMedia = [...displayMedia, ...mediaUrls]
  }

  return (
    <Form>
      {GalleryModal()}
      {/* <Button variant="primary" disabled={uploading} onClick={handleSaveActivity} >Save</Button> */}


      <Form.Group controlId="images">
        <Form.Row>

          <ImageCollage mediaUrls={displayMedia} onDelete={(url: string) => removeImage(url)} >
            <div>
              <div style={{ position: "relative" }}>
                <Button style={{ position: "absolute", margin: "1em" }}
                  onClick={handleShowGalleryModal}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
              <img
                src={"https://via.placeholder.com/160x377"}
                style={{ width: "100%", maxHeight: "100em", maxWidth: "10em", borderRadius: "1em", margin: "0.4em" }} />
            </div>
            <FileUploader key="uploader" onInput={async (e: any) => {
              const p: any[] = []
              for (let i = 0; i < e.target.files.length; i++) {
                const blob = await getBase64URL(e.target.files[i]).then((base64URL) => {
                  return resizeAndCrop(base64URL)
                }).catch((error) => {
                  console.error(error)
                });
                p.push(blob)
              }
              saveMedia(p)
            }} />
          </ImageCollage>

        </Form.Row>
      </Form.Group>

      <Form.Row>
        <Col xs={7}>
          <Form.Group controlId="title">
            <Form.Label>Information</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Title"
                value={activity.title || ""}
                onChange={(e) => {
                  setActivity((prevState: any) => ({ ...prevState, title: e.target.value }))
                }}
              />
              <Button disabled={uploading} onClick={handleSaveActivity} variant="outline-secondary" id="button-addon2" >Save</Button>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="description">
            <InputGroup>
              <Form.Control
                placeholder="Description"
                value={activity.description || ""}
                onChange={(e) => {
                  setActivity((prevState: any) => ({ ...prevState, description: e.target.value }))
                }}
                as="textarea"
                className="form-control overflow-hide"
                rows={4}
                style={{ "resize": "none" }}
              />
              <Button disabled={uploading} onClick={handleSaveActivity} variant="outline-secondary" id="button-addon2" >Save</Button>
            </InputGroup>
          </Form.Group>

          <Form.Label>Where</Form.Label>
          <Form.Group controlId="locationName">
            <InputGroup>
              <Form.Control
                placeholder="Location Name"
                value={activity.locationName || ""}
                onChange={(e) => {
                  setActivity((prevState: any) => ({ ...prevState, locationName: e.target.value }))
                }}
              />
              <Button disabled={uploading} onClick={handleSaveActivity} variant="outline-secondary" id="button-addon2" >Save</Button>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="locationAddress">
            <Form.Control
              placeholder="Location Address"
              value={activity.locationAddress || ""}
              onChange={(e) => {
                setActivity((prevState: any) => ({ ...prevState, locationAddress: e.target.value }))
              }}
            />
          </Form.Group>
        </Col>
        <Col >
          <MapLibre
            onMove={async (c: any) => {
              const code = await reverseGeocode({ lat: c.lat, lon: c.lng })
              const nameIs = locationNameMaker(code)
              setActivity((prevState: any) => ({ ...prevState, locationLat: c.lat, locationLon: c.lng, locationAddress: nameIs.extras, locationName: nameIs.title }))
            }}
            ipCenter={{ lat: activity.locationLat || 42.2732991, lng: activity.locationLon || -83.8077293 }}
          />
        </Col>
      </Form.Row >

      <Form.Group>

        {renderPickerList()}
      </Form.Group>

    </Form >
  )
}
