// react 
import { useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";

// libs
import dateFormat from "dateformat"

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

// UI
import { Button, Table, Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import ActivityPreview from "../components/ActivityPreview"

// firebase
import firebase from "firebase";

// CSS
import "./StumbledActivities.css";


TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

export default function StumbledActivities() {
  const { user } = useAppContext();


  // on page load
  // Trigger stumble activities by providing location and search radius
  // Start listening to the query.
  // return unsubscribe for subscription cleanup when function isn't needed anymore.
  const [interestedStumbledActivities, setInterestedStumbledActivities] = useState<any[]>([]);
  useEffect(() => {

    if (!user) {
      return
    }

    var unsubscribe = firebase.firestore()
      .collection('activities/').where('interested', 'array-contains', user.uid)
      .onSnapshot(function (snapshot) {
        console.log({ snapshot })

        setInterestedStumbledActivities([])
        snapshot.docs.map((activity: any) => {
          console.log(activity)
          return firebase.firestore().collection('activities').doc(activity.id).get()
            .then((a) => {
              setInterestedStumbledActivities(oldArray => [...oldArray, { activityId: a.id, ...a.data() }])
            })
        })

      });

    return function cleanup() {
      console.log("Unsubscribing from stumbled activities")
      unsubscribe()
    }
  }, [user]);

  // on page load
  // Trigger stumble activities by providing location and search radius
  // Start listening to the query.
  // return unsubscribe for subscription cleanup when function isn't needed anymore.
  const [acceptedStumbledActivities, setAcceptedStumbledActivities] = useState<any[]>([]);
  useEffect(() => {

    if (!user) {
      return
    }

    var unsubscribe = firebase.firestore()
      .collection('activities/').where('accepted', 'array-contains', user.uid)
      .onSnapshot(function (snapshot) {
        console.log({ snapshot })

        setAcceptedStumbledActivities([])
        snapshot.docs.map((activity: any) => {
          console.log(activity)
          return firebase.firestore().collection('activities').doc(activity.id).get()
            .then((a) => {
              setAcceptedStumbledActivities(oldArray => [...oldArray, { activityId: a.id, ...a.data() }])
            })
        })

      });

    return function cleanup() {
      console.log("Unsubscribing from stumbled activities")
      unsubscribe()
    }
  }, [user]);



  // on page load
  // Trigger stumble activities by providing location and search radius
  // Start listening to the query.
  // return unsubscribe for subscription cleanup when function isn't needed anymore.
  const [heartedStumbledActivities, setHeartedStumbledActivities] = useState<any[]>([]);
  useEffect(() => {

    if (!user) {
      return
    }

    var unsubscribe = firebase.firestore()
      .collection('activities').where('hearted', 'array-contains', user.uid)
      .onSnapshot(function (snapshot) {
        console.log({ snapshot })

        setHeartedStumbledActivities([])
        snapshot.docs.map((activity: any) => {
          console.log(activity)
          return firebase.firestore().collection('activities').doc(activity.id).get()
            .then((a) => {
              setHeartedStumbledActivities(oldArray => [...oldArray, { activityId: a.id, ...a.data() }])
            })
        })

      });

    return function cleanup() {
      console.log("Unsubscribing from stumbled activities")
      unsubscribe()
    }
  }, [user]);


  const [selectedActivity, setSelectedActivity] = useState<any | null>(null);

  // Create the query to load the last 12 messages and listen for new ones.
  // return unsubscribe for subscription cleanup when function isn't needed anymore.
  // const [messages, setMessages] = useState<any[]>([]);
  // useEffect(() => {
  //   if (selectedActivity) {

  //     var unsubscribe = firebase.firestore()
  //       .collection('activities')
  //       .doc(selectedActivity.activityId)
  //       .collection('messages')
  //       .orderBy('locationHash', 'desc')

  //       .limit(12)
  //       .onSnapshot(function (snapshot) {
  //         setMessages(snapshot.docs.map((d) => { return { messageId: d.id, ...d.data() } }))
  //       });
  //     return function cleanup() {
  //       setMessages([])
  //       console.log(`Unsubscribing from activity ${selectedActivity.activityId} messages`)
  //       unsubscribe()
  //     }
  //   }

  // }, [selectedActivity]);


  async function remove(activityId: string, personId: string) {
    console.log("Remove person interested", { personId, activityId })
    firebase.firestore()
      .collection('activities')
      .doc(activityId)
      .set({ interested: firebase.firestore.FieldValue.arrayRemove(personId) }, { merge: true })
      .then(() => console.log("Wrote personId to stumbed", { personId, activityId }))
      .catch((error) => console.error("Error occured while writing activities to stumble", { error }));
  }


  async function removeAccepted(activityId: string, personId: string) {
    console.log("Remove person accepted", { personId, activityId })
    firebase.firestore()
      .collection('activities')
      .doc(activityId)
      .set({ accepted: firebase.firestore.FieldValue.arrayRemove(personId) }, { merge: true })
      .then(() => console.log("Wrote personId to stumbed", { personId, activityId }))
      .catch((error) => console.error("Error occured while writing activities to stumble", { error }));
  }


  async function removeHearted(activityId: string, personId: string) {
    console.log("Remove person hearted", { personId, activityId })
    firebase.firestore()
      .collection('activities')
      .doc(activityId)
      .set({ hearted: firebase.firestore.FieldValue.arrayRemove(personId) }, { merge: true })
      .then(() => console.log("Wrote personId to stumbed", { personId, activityId }))
      .catch((error) => console.error("Error occured while writing activities to stumble", { error }));
  }


  // async function saveMessage(messageText: string) {
  //   // Add a new message entry to the database.
  //   if (selectedActivity.activityId) {
  //     try {
  //       return firebase.firestore().collection('activities').doc(selectedActivity.activityId).collection("messages").add({
  //         name: displayName,
  //         personId: user?.uid,
  //         text: messageText,
  //         profilePicUrl: photoUrl,
  //         timestamp: firebase.firestore.FieldValue.serverTimestamp()
  //       });
  //     } catch (error) {
  //       console.error('Error writing new message to database', error);
  //     }
  //   }
  // }


  // function messageRender() {
  //   const messageTableList = messages.map((message) => {

  //     return (
  //       <tr key={message.messageId}>
  //         <td><Image
  //           width={50}
  //           height={50}
  //           src={message.profilePicUrl}
  //           roundedCircle /></td>
  //         <td><div><pre>{JSON.stringify(message, null, 2)}</pre></div></td>
  //       </tr>
  //     )
  //   })

  //   return (
  //     <>
  //       <h2>Activity Messages</h2>
  //       <p>{selectedActivity.id}</p>
  //       <Table striped bordered hover>
  //         <thead>
  //           <tr>
  //             <th>media</th>
  //             <th>json</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {messageTableList}
  //         </tbody>
  //       </Table>
  //     </>
  //   )
  // }

  function activityListRender(activities: any[], remove: any) {

    if (!user) {
      return
    }

    const activityTableList = activities.map((activity, index) => {
      const startDate = activity.startTime && dateFormat(activity.startTime.toDate(), "dddd, mmmm dS")
      const timeago = activity.startTime && timeAgo.format(activity.startTime.toDate())

      return (
        <tr key={index} onClick={(e: any) => setSelectedActivity({ ...activity, activityId: activity.activityId })}>
          <td>
            <Button
              onClick={() => {
                console.log("removing", { activity })
                remove(activity.activityId, user.uid)
              }}
            ><FontAwesomeIcon icon={faThumbsDown} />
            </Button>
          </td>
          <td>{startDate} {timeago}</td>
          <td>{activity.title}</td>
          <td>{activity.locationName}</td>
        </tr>
      )
    })

    return (
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Title</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {activityTableList}
        </tbody>
      </Table>
    )
  }


  return (
    <div className="StumbledActivities">
      <div className="lander">

        <Container fluid>
          <Row lg="3">
            <Col><h1>I'm Interested</h1></Col>
            <Col><h1>Preview</h1></Col>
            <Col><h1>JSON</h1></Col>
          </Row>
          <Row lg="3">
            <Col>
              <Row lg="1">
                <Col>
                  {activityListRender(interestedStumbledActivities, remove)}
                </Col>
              </Row>
              <Row lg="1"><h1>I'm Accepted</h1></Row>
              <Row lg="1">
                <Col>
                  {activityListRender(acceptedStumbledActivities, removeAccepted)}
                </Col>
              </Row>
              <Row lg="1"><h1>I Hearted</h1></Row>
              <Row lg="1">
                <Col>
                  {activityListRender(heartedStumbledActivities, removeHearted)}
                </Col>
              </Row>

            </Col>
            <Col>
              <ActivityPreview selectedActivity={selectedActivity} />
            </Col>
            <Col  >
              <div ><pre style={{ overflowWrap: 'break-word', textAlign: "left", wordBreak: "break-all" }}>
                {selectedActivity && JSON.stringify(selectedActivity, null, 2)}
              </pre></div>
            </Col>
          </Row>
        </Container>
      </div>
    </div >
  );
}
