// react 
import { useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";

// libs
import dateFormat from "dateformat"

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

// UI
import { Button, Table, Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown, faStar } from '@fortawesome/free-solid-svg-icons'
import ActivityPreview from "../components/ActivityPreview"

// firebase
import firebase from "firebase";

// CSS
import "./StumbleOnActivities.css";


TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

export default function StumbleOnActivities() {
  const { user } = useAppContext();

  // useEffect(() => {

  //   if (!user) {
  //     return
  //   }

  //   // Add a new people entry to the database.
  //   const lat = 51.5074;
  //   const lon = 0.1278;

  //   firebase.firestore().collection('persons').doc(user.uid).set({
  //     locationLat: lat,
  //     locationLon: lon,
  //     radiusInM: 100000,
  //     addRecommended: true
  //   }, {
  //     merge: true
  //   })
  //     .then((d) => console.log("Set/Updated Person", d))
  //     .catch((e) => console.error("Error writing new person to database"));

  // }, [user]);

  // on page load
  // Trigger stumble activities by providing location and search radius
  // Start listening to the query.
  // return unsubscribe for subscription cleanup when function isn't needed anymore.
  const [stumbleOnActivities, setStumbleOnActivities] = useState<any[]>([]);
  useEffect(() => {

    if (!user) {
      return
    }

    var unsubscribe = firebase.firestore()
      .collection('persons').doc(user.uid)
      .onSnapshot(function (snapshot) {
        const recommendedActivities = snapshot.data()?.recommendedActivities

        if (!recommendedActivities) {
          return
        }

        console.log("RecommendedActivities", recommendedActivities)

        recommendedActivities.map((activityId: any) => {
          return firebase.firestore().collection('activities').doc(activityId).get()
            .then((a) => {
              setStumbleOnActivities(oldArray => [...oldArray, { activityId: a.id, ...a.data() }])
            })
        })

      });

    return function cleanup() {
      console.log("Unsubscribing from stumbleOn activities")
      unsubscribe()
    }
  }, [user]);

  const [selectedActivity, setSelectedActivity] = useState<any | null>(null);


  async function getRecommended() {
    if (!user?.uid) {
      return
    }
    // Add a new people entry to the database.
    const lat = 42.22630397311676
    const lon = -83.74469257037606
    const startTime = new Date(Date.now())

    firebase.firestore().collection('persons').doc(user.uid).set({
      locationLat: lat,
      locationLon: lon,
      radiusInM: 100000,
      addRecommended: true,
      recommendedActivities: [],
      startTime: firebase.firestore.Timestamp.fromDate(startTime),
    }, {
      merge: true
    })
      .then((d) => console.log("Set/updated Person", { personId: user.uid }))
      .catch((e) => console.error("Error writing new person to database"));
  }


  async function interested(activityId: string, personId: string) {
    console.log("Person Interested", { personId, activityId })
    firebase.firestore()
      .collection('activities')
      .doc(activityId)
      .set({ interested: firebase.firestore.FieldValue.arrayUnion(personId) }, { merge: true })
      .then(() => {
        console.log("Wrote personId to stumbed", { personId, activityId })
        setStumbleOnActivities(oldArray => {
          const newarr = oldArray.filter(item => item.activityId !== activityId)
          return newarr
        })
      })
      .catch((error) => console.error("Error occured while writing activities to stumble", { error }));
  }

  async function hearted(activityId: string, personId: string) {
    console.log("Person Interested", { personId, activityId })
    firebase.firestore()
      .collection('activities')
      .doc(activityId)
      .set({ hearted: firebase.firestore.FieldValue.arrayUnion(personId) }, { merge: true })
      .then(() => {
        console.log("Wrote personId to stumbed", { personId, activityId })
        setStumbleOnActivities(oldArray => {
          const newarr = oldArray.filter(item => item.activityId !== activityId)
          return newarr
        })
      })
      .catch((error) => console.error("Error occured while writing activities to stumble", { error }));
  }


  function activityListRender(activities: any[]) {

    if (!user) {
      return
    }

    const activityTableList = activities.map((activity, index) => {
      const startDate = activity.startTime && dateFormat(activity.startTime.toDate(), "dddd, mmmm dS")
      const timeago = activity.startTime && timeAgo.format(activity.startTime.toDate())

      return (
        <tr key={index} onClick={(e: any) => setSelectedActivity({ ...activity, activityId: activity.activityId })}>
          <td>
            <Col>
              <Row>
                <Button
                  onClick={() => interested(activity.activityId, user.uid)}
                ><FontAwesomeIcon icon={faThumbsUp} /></Button>
              </Row>
              <Row>
                <Button variant="warning"
                  onClick={() => hearted(activity.activityId, user.uid)}
                ><FontAwesomeIcon icon={faStar} /></Button>
              </Row>
              <Row>
                <Button variant="danger"
                  onClick={() => {
                    setStumbleOnActivities(oldArray => {
                      const newarr = oldArray.filter(item => item.activityId !== activity.activityId)
                      return newarr
                    })
                  }}
                ><FontAwesomeIcon icon={faThumbsDown} /></Button>
              </Row>
            </Col>
          </td>
          <td>{startDate}</td>
          <td>{timeago}</td>
          <td>{activity.title}</td>
          <td>{activity.locationName}</td>

        </tr>
      )
    })

    return (
      <Table>
        <thead>
          <tr>
            <th>actions</th>
            <th>Date</th>
            <th></th>
            <th>Title</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {activityTableList}
        </tbody>
      </Table>
    )
  }


  return (
    <div className="StumbleOnActivities">
      <div className="lander">
        <Button onClick={() => getRecommended()}>Get Activities</Button>
        <Container fluid>
          <Row lg="3">
            <Col><h1>Recommended</h1></Col>
            <Col><h1>Preview</h1></Col>
            <Col><h1>JSON</h1></Col>
          </Row>
          <Row lg="3">
            <Col>
              {activityListRender(stumbleOnActivities)}
            </Col>
            <Col>
              <ActivityPreview selectedActivity={selectedActivity} />
            </Col>
            <Col  >
              <div ><pre style={{ overflowWrap: 'break-word', textAlign: "left", wordBreak: "break-all" }}>
                {selectedActivity && JSON.stringify(selectedActivity, null, 2)}
              </pre></div>
            </Col>
          </Row>
        </Container>
      </div>
    </div >
  );
}
