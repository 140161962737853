// react 

// libs
import dateFormat from "dateformat"
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import ImageCollage from "./ImageCollage"

// UI
import { Card, Carousel, ListGroup, ListGroupItem, } from "react-bootstrap"
import { ReactPhotoCollage } from "react-photo-collage";

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

export default function ActivityPreview({
  selectedActivity
}) {
  if (!selectedActivity) {
    return (
      <></>
    )
  }

  let startDate = ""
  let timeago = ""

  if (!selectedActivity.anytime) {
    startDate = selectedActivity.startTime && dateFormat(selectedActivity.startTime.toDate(), "dddd, mmmm dS")
    timeago = selectedActivity.startTime && timeAgo.format(selectedActivity.startTime.toDate())
  }
  const acceptedCount = selectedActivity?.accepted?.length
  const activity = selectedActivity


  let displayMedia = []
  if (activity.mediaUrls.length > 0) {
    const mediaUrls = activity.mediaUrls.map((f) => {
      return { mediaUrl: f }
    })

    displayMedia = [...displayMedia, ...mediaUrls]
  }

  //424, 1000
  //activity.hostPhotoUrl
  return (
    <Card >
      <Card.Header><h1>{activity.title} by {activity.hostName}</h1></Card.Header>
      <ImageCollage mediaUrls={displayMedia} />
      <Card.Body>
        <Card.Title>{startDate}</Card.Title>
        <Card.Text>
          {activity.description}
        </Card.Text>
        <ListGroup className="list-group-flush">
          <ListGroupItem>{acceptedCount}{activity.maxAttendees ? `/${activity.maxAttendees}` : ""}</ListGroupItem>
        </ListGroup>
      </Card.Body>
      <Card.Footer className="text-muted">{timeago} @ {activity.locationName}</Card.Footer>
    </Card >
  )
}