import { useContext, createContext } from "react";
import firebase from "firebase";

// We define our type for the context properties right here
type ContextProps = {
  isAuthenticated: boolean,
  user: firebase.User | null,
  selectedTimeZone: string
};

export const AppContext = createContext<ContextProps>({
  isAuthenticated: false,
  user: null,
  selectedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
});

export function useAppContext() {
  return useContext(AppContext);
}
