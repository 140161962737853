import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from 'firebase/app';
import 'firebase/messaging';

// import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


//init firebase
const firebaseConfig = {
  apiKey: "AIzaSyDv3a2NS6lYBoipa8JFUFv0gwybOXdldfU",
  authDomain: "vivacious-9e338.firebaseapp.com",
  databaseURL: "https://vivacious-9e338-default-rtdb.firebaseio.com",
  projectId: "vivacious-9e338",
  storageBucket: "vivacious-9e338.appspot.com",
  messagingSenderId: "716085428423",
  appId: "1:716085428423:web:ae3a82394d3963aba2bee2",
  measurementId: "G-9V91VWZ3CD"
};

firebase.initializeApp(firebaseConfig);

if (firebase.messaging.isSupported()) {
  firebase.messaging();
  firebase.messaging().getToken({ vapidKey: 'BCpltFt0exrfSZvmAEZsoOdBecgmR4yH-jfwUSDED1h8J8z9-7FwpSKcIcsjAz3y0qgfGc9kPjEquaNMct9htyE' })
}

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
