// react
import { useState } from "react";

// firebase
import firebase from "firebase";

// libs
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib"

// UI
import LoaderButton from "../components/LoaderButton";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Form } from "react-bootstrap";
import { GoogleLoginButton } from "react-social-login-buttons";


// CSS
import "./Login.css";


export default function Login() {
  const auth = firebase.auth()

  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function signInAnon() {
    try {
      auth.signInAnonymously()
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }


  };

  function signInGoogle() {
    try {
      var provider = new firebase.auth.GoogleAuthProvider()
      provider.setCustomParameters({
        hd: "stumble.host"
      });
      auth.signInWithPopup(provider)
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }

  }

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmitLoginWithEmail(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      auth.signInWithEmailAndPassword(fields.email, fields.password)
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div>
      <GoogleLoginButton onClick={() => signInGoogle()} />
      <LoaderButton
        block
        isLoading={isLoading}
        onClick={() => signInAnon()}
      >
        Anonymous Login
      </LoaderButton>
      <form onSubmit={handleSubmitLoginWithEmail}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            as="input"
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            value={fields.password}
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>

      <LinkContainer to="/signup">
        <Nav.Link className="signup-link">Sign up here</Nav.Link>
      </LinkContainer>

    </div>
  );
}