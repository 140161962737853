// convert a base64 image file to image dom element
function _createImg(photo) {
  const img = new Image();
  img.src = photo;
  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
  });
}

function prefixFileName(url, prefix) {
  let filename = url.split("%2F").pop();
  filename = `${prefix}${filename}`;
  return `${url.split("%2F").slice(0, -1).join("%2F")}%2F${filename}`;
}


async function getBase64URL(file) {
  if (!file || !file.type.startsWith("image/"))
    throw new Error("Only images are accepted (jpeg, gif, png)");
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve) => {
    reader.onload = (e) => {
      resolve(e.target.result);
    };
  });
}


async function resizeImg(photo, dWidth, dHeight) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = await _createImg(photo);

  let sWidth = img.naturalHeight * (dWidth / dHeight);
  let sx = Math.abs(sWidth - img.naturalWidth) / 2;

  let sHeight = img.naturalHeight;
  let sy = 0;

  let dx = 0;
  let dy = 0;

  canvas.width = dWidth;
  canvas.height = dHeight;

  console.log("redraw dims", { sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight })

  ctx.drawImage(img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
}

function blobToDataURL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = _e => resolve(reader.result);
    reader.onerror = _e => reject(reader.error);
    reader.onabort = _e => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
}

//  425 wide x 1000
async function resizeAndCrop(photo) {
  return resizeImg(photo, 424, 1000).then(async (photo) => {
    return photo
  }).catch((error) => console.error("There was a problem resizing and cropping the image", { error }))
}

export { resizeAndCrop, getBase64URL, prefixFileName };