import { faMinus, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"


export default function ImageCollage({ children, mediaUrls, onDelete = (_) => { }, onAdd = (_) => { } }) {

  const photos = mediaUrls?.map((u, i) => {
    return (
      <div key={i}>
        {u.upload === true &&
          <div style={{ position: "relative" }} >
            <Button style={{ position: "absolute", margin: "1em" }}>
              <FontAwesomeIcon icon={faUpload} />
            </Button></div>}
        {u.upload === false &&
          <div style={{ position: "relative" }} >
            <Button style={{ position: "absolute", margin: "1em" }}
              onClick={() => onDelete(u.mediaUrl)}>
              <FontAwesomeIcon icon={faMinus} />
            </Button></div>}
        {u.add === true &&
          <div style={{ position: "relative" }} >
            <Button style={{ position: "absolute", margin: "1em" }}
              onClick={() => onAdd(u.mediaUrl)}>
              <FontAwesomeIcon icon={faPlus} />
            </Button></div>}
        <img src={u.mediaUrl} style={{ width: "100%", maxHeight: "100em", maxWidth: "10em", borderRadius: "1em", margin: "0.4em" }} />
      </div>
    )
  })

  return (<div style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "left", alignItems: "center" }}>
    {photos}
    {children}
  </div>)
}